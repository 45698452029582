import { 
  login, 
  // getUserMinimal,
  // updateUser, 
  // updatePassword, 
  // registerUser, 
  // deleteUser 
} from './action';
import { userSlice } from './user';

export const { setUser, setUsersMin } = userSlice.actions;
export { login };

export default userSlice.reducer;
