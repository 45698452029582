import React, { lazy, Suspense } from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate, useLocation } from 'react-router';
import { Routes, Route } from "react-router-dom";
import LoadingPage from '../components/layouts/LoadingPage';
// import MainBase from '../components/layouts/MainBase';
// import { RootState } from '../stores';

const load = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingPage />}>
    <Component {...props} />
  </Suspense>
);

// const Barcodes = load(lazy(() => import('../pages/Barcodes')));
// const Dashboard = load(lazy(() => import('../pages/Dashboard')));
// const Laporan = load(lazy(() => import('../pages/Laporan')));
// const Login = load(lazy(() => import('../pages/Login')));
// const Produk = load(lazy(() => import('../pages/Produk')));
// const ProdukModify = load(lazy(() => import('../pages/ProdukModify')));
// const ProdukNew = load(lazy(() => import('../pages/ProdukNew')));

// Landing Page
const LandingPage = load(lazy(() => import('../pages/LandingPage/Dashboard')));
// const Reports = load(lazy(() => import('../pages/LandingPage/Reports')));

// const ProtectedRoutes: React.FC<{ user: User }> = ({ user }) => {  
//   const location = useLocation();

//   if(user.id === -1 && location.pathname !== '/login'){
//     return <Navigate replace to="/login" />;
//   }

//   return (
//     <MainBase>
//       <Routes>
//         <Route path="dashboard" element={<Dashboard />} />
//         <Route path="laporan" element={<Laporan />} />
//         <Route path="produk" element={<Produk />} />
//         <Route path="produk/new" element={<ProdukNew />} />
//         <Route path="produk/:id" element={<ProdukModify />} />
//         <Route path="barcodes" element={<Barcodes />} />
//       </Routes>
//     </MainBase>
//   );
// };

const AppRoutes: React.FC = () =>  {
  // const { user } = useSelector((s: RootState) => s.user);

  return (
    <Routes>
      <Route index element={<LandingPage />}/>
      {/* <Route path="reports/:code" element={<Reports />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<ProtectedRoutes user={user} />} /> */}
    </Routes>
)};

export default AppRoutes;
