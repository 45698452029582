import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser } from '../../utils/storage';

interface UserState {
  user: User,
  usersMin: UserMinimal[],
}

const initialState: UserState = {
  user: {
    id: -1,
    roleId: -1,
    roles: null,
    nip: '',
    email: '',
    name: '',
    jabatan: '',
    address: '',
    phone: '',
    authorization: {
      token: null,
      type: null,
    },
  },
  usersMin: [],
};

const getInitialState = (): UserState => {
  const user = getUser();
  if (user == null) {
    return initialState;
  }
  return { ...initialState, user };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    setUsersMin: (state, { payload }: PayloadAction<UserMinimal[]>) => (
      { ...state, usersMin: payload }
    ),
  },
});
