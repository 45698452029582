import { Intent } from '@blueprintjs/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSidebar } from '../utils/storage'

export interface UIToast {
  intent: Intent,
  message: string,
}

interface UIState {
  dark?: boolean,
  loading: boolean,
  loadingCount: number,
  toast: UIToast,
  sidebar: SidebarState,
}

const initialState: UIState = {
  dark: false,
  loading: false,
  loadingCount: 0,
  toast: { intent: 'none', message: '' },
  sidebar: getSidebar() || 'expand',
};

export const uiSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setDarkMode: (state, { payload }: PayloadAction<boolean>) => {
      state.dark = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingCount = payload ? state.loadingCount + 1 : state.loadingCount - 1;
      state.loading = state.loadingCount > 0;
    },
    setMessage: (state, { payload }: PayloadAction<UIToast>) => {
      state.toast = payload;
    },
    setSidebar: (state, { payload }: PayloadAction<SidebarState>) => {
      state.sidebar = payload;
    },
  },
});

export const { 
  setDarkMode,
  setLoading,
  setMessage,
  setSidebar,
} = uiSlice.actions;

export default uiSlice.reducer;
